import React, {} from "react";

import ClientCard from "../client-card/ClientCard";
import BlockHeader from "../block-header/BlockHeader";
import Chall from './../../images/Chall.png';
import techpros from './../../images/techpros.svg';

import styles from './OurClientsBlock.module.css';

const OurClientsBlock = ({clients}) => {

    const header = {
        title: 'Our Clients'
    };

    const mockLogos = [{
        __typename: "Client",
        id: "6203aad5e5583005751badc7123",
        image: {
            name: "Chall",
            url: Chall
        }
    }, {
        __typename: "Client",
        id: "6203aad5e5583005751badc7321",
        image: {
            name: "Techpros",
            url: techpros
        }
    }]

    return (<>
        { clients?.length && <div className={ styles.ourClients }>
            <div className={styles.header}>
                <BlockHeader header={ header } />
            </div>

            <div className={ styles.clientList }>
                {
                    clients.map(client => {
                        return <ClientCard key={client.id} client={client}/>
                    })
                }
                {/* TODO: add to API. Mock data for now */}
                {mockLogos.map(logo => {
                    return (
                        <div className={ styles.client } key={logo.id}>
                            <img src={ `${logo.image.url}` } alt={`${logo.image.name}`} />
                        </div>
                    )
                })}
                    
            </div>
        </div> }
    </>)
};

export default OurClientsBlock;
