import React from "react";
import classNames from "classnames";

import styles from './WorkStep.module.css';
import environment from "../../environments/environment";

const WorkStep = ({ step }) => {
    return (
        <div>
            {
                step.order %2 === 0 ?

                    <div className={ styles.step }>
                        <img className={`${environment.apiUrl}${styles.images}`} src={ `${environment.apiUrl}${step.image?.url}` } alt=""/>
                        <div className={ styles.item }>
                            <div className={ styles.circleLeft } />
                            <div className={ classNames(styles.line, styles.leftWithCircle) } />
                            <span className='accent-text'>{ step?.title }</span>
                            <div className={ classNames(styles.line, styles.rightSimple) } />
                        </div>
                    </div>

                    :

                    <div className={ classNames(styles.step, styles.stepReverse) }>
                        <div className={ classNames(styles.item, styles.itemReverse) }>
                            <div className={ classNames(styles.line, styles.leftSimple) } />
                            <span className='accent-text'>{ step?.title }</span>
                            <div className={ classNames(styles.line, styles.rightWithCircle) } />
                            <div className={ styles.circleRight } />
                        </div>
                        <img className={`${environment.apiUrl}${styles.images}`} src={ `${environment.apiUrl}${step.image?.url}` } alt=""/>
                    </div>
            }
        </div>
    )
};

export default WorkStep;
