import React from "react";

import styles from "./ClientCard.module.css";
import environment from "../../environments/environment";

const ClientCard = ({ client }) => {
    return (
        <div className={ styles.client }>
            <img src={ `${environment.apiUrl}${client.image.url}` } alt=''/>
        </div>
    )
};

export default ClientCard;
